import React from "react";
import './pageStyles/projects.css';

const ProjectsPage = () => {
	return (
		<div className='projects-page-container'>
		  <div>Welcome to the projects page (under construction)</div>
		</div>
	  )
}

export { ProjectsPage };
