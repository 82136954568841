import React from "react";
import './pageStyles/work.css';

const WorkPage = () => {
	return (
		<div className='work-page-container'>
		  <div>Welcome to the work page (under construction)</div>
		</div>
	  )
}

export { WorkPage };